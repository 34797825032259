/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Montserrat:ital,wght@0,500;0,700;0,900;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,400;1,500&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/*
.App {
    font-family: 'Poppins', sans-serif;
    text-align: center;
}
*/
.App {
    font-family: 'Comic Neue', cursive;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Poppins', sans-serif;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}
