

.destination {
    margin: 4rem 6rem;
    font-family: 'Comic Neue', cursive;
}

.destination p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
}

.destination h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
}

.first-des {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
    margin-bottom: 9rem;
}
.first-des-reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}


.des-text {
    width: 45%;
    text-align: start;
    font-size: 1.5rem;
}

.des-text h2 {
    padding-bottom: 1.3rem;
}


.images-destination {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-right: .7rem;
    z-index: -8;
}

.images-destination img {
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px;
    /*
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    */
}

.images-destination img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
}

@media screen and (max-width: 850px) {

    .destination {
    margin: 4rem 2rem;
    
    }

    .images-destination {
    padding-right: 1rem;
    }

    .first-des {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4rem;
        margin-bottom: 8rem;
    }
    .first-des-reverse {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 5rem;
    }

    .des-text {
        width: 90%;
        text-align: start;
        font-size: 1.1rem;
    }

    .images-destination {
        width: 90%;
        margin: 1.8rem 0 4rem 0;
    }

    .images-destination img {
        height: 300px;
    }
}