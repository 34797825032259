@import url(https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Montserrat:ital,wght@0,500;0,700;0,900;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,400;1,500&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/*
.App {
    font-family: 'Poppins', sans-serif;
    text-align: center;
}
*/
.App {
    font-family: 'Comic Neue', cursive;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Poppins', sans-serif;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}


.hero {
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;
    
}


img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    padding: 1rem 0.8rem 1.5rem 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;

}



.hero-text h1 {
    font-size: 3rem;
    font-family: 'Comic Neue', cursive;
    font-weight: 800;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
}

.hero-text p {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px); /* For compatibility with some browsers */
    max-width: 600px;
    font-family: 'Comic Neue', cursive;
    
    font-size: 1.6rem;
    font-weight: 700;
    font-style: italic;
    color: #000000;
    padding: 0.5rem 0 2rem 0;
}

.hero-text .show {
    text-decoration: none;
    background-color: #800000;
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
}

.hero-text .show:hover {
    text-decoration: none;
    background-color: #FF0000;
    color: #000000;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
   
}
.hero-text .hide {
    display: none;
}



@media screen and (max-width: 555px) {
    .hero-text {
        top: 50%;
    }
    .hero-text h1{
        padding: 5px 10px;
        font-size: 2.5rem;

    }
    .hero-text p{
        font-size: 1.2rem;
        padding: 0 0 2rem 0;
    }

    .hero-text .show {

        padding: .8rem 2rem;
        border-radius: 0.5rem;
        font-size: .8rem;
    }
}

.navbarItems {
    background-color: rgba(247, 248, 248, 0.95);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .25);
    width: 95%;
    height: 5rem;
    border-radius: .5rem;
    position: fixed;
    top: 1.25rem;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 10;
    
}

.navbar-logo {
    padding-top: 1rem;
    color: #222;
    font-size: 2rem;
    cursor: pointer;
    width: 5rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: .7rem;
    gap: .7rem;
    list-style-type: none;
    align-items: center;

}

.nav-links {
    text-decoration: none;
    color: #222;
    font-size: 1.4rem;
    font-family: 'Comic Neue', cursive;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;

}
.nav-links:hover {
    background-color: #67b4d6;
    color: #fff;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
     
}

.nav-links i{
    padding-right: .6rem;
}

.nav-links-mobile {
    display: none;
}
.signUp-button {
    padding: 0.8rem 1.6rem;
    white-space: nowrap;
    border-radius:  0.3rem;
    font-size: 1.4rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background-color: #800000;
    color: #fff;
    transition: all .3s;
}

.signUp-button:hover{
    background-color: #FF0000;
    color: #000000;
    transition: all .3s;

}

.menu-icons{
    display: none;
}

@media screen and (max-width: 850px) {

    .navbarItems {
        background-color: rgba(124, 213, 213, 0.01);
        box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .010);
        width: 95%;
        height: 4rem;
        border-radius: .5rem;
        position: fixed;
        top: 1.25rem;
        left: 50%;
        -webkit-transform: translate(-50%);
                transform: translate(-50%);
        transition: all 1.2s ease-in-out;
        
    }
    .navbarItems:hover{
        height: 5rem;
        background-color: rgba(247, 248, 248, 0.85);
        box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .35); 
        transition: all 0.4s ease-in-out;
    }


    .navbar-logo {
        width: 4rem;
    }


    .navbarItems {
        z-index: 8;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba(61, 62, 80, 0.4);
        width: 100%;
        height: auto;
        position: absolute;
        top: -300%;
        left: 120%;
        opacity: .1;
        align-items: stretch;
        padding: 68px 0 30px 0;
        margin: 0;  
        z-index: -1;
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0.0rem);
        transition: all 1s;
        transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
    }
    .nav-menu.active{
        top: 0;
        left: 0;
        opacity: 1;
        transition: all 1.2s;
        background-color: rgba(73, 83, 88, 0.4);
         backdrop-filter: blur(0.4rem);
        -webkit-backdrop-filter: blur(0.4rem);
    }

    .nav-links{
        display: block;
        width: 98%;
        margin: auto;
        font-size: 1.4rem;
        padding: 1.8rem 0;
    }

    .nav-links:hover{
        background: #67b5d6d2;
        transition: none;
    }
    .signUp-button {
        display: none;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 2rem;
        margin: auto;
        width: 80%;
        text-decoration: none;
        background-color: #800000;
        color: #fff;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 800;
        letter-spacing: 3px;
        
    }
    .nav-links-mobile:hover {
        background-color: #FF0000;
        color: #000000;

    }

    .menu-icons{ 
        display: block;
        cursor: pointer;
    }
    .menu-icons i {
        font-size: 2rem;
        color: #222;
    }
}

@media screen and (max-width: 450px) {

    .navbarItems {
        background-color: rgba(124, 213, 213, 0.01);
        box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .010);
        width: 95%;
        height: 4rem;
        border-radius: .5rem;
        position: fixed;
        top: 1.25rem;
        left: 50%;
        -webkit-transform: translate(-50%);
                transform: translate(-50%);
        transition: all 1.2s ease-in-out;
        
    }
    .navbarItems:hover{
        height: 5rem;
        background-color: rgba(247, 248, 248, 0.85);
        box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .35); 
        transition: all 0.4s ease-in-out;
    }


    .navbar-logo {
        width: 4rem;
    }


    .navbarItems {
        z-index: 8;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba(61, 62, 80, 0.4);
        width: 100%;
        height: auto;
        position: absolute;
        top: -300%;
        left: 120%;
        opacity: .1;
        align-items: stretch;
        padding: 68px 0 30px 0;
        margin: 0;  
        z-index: -1;
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0.0rem);
        transition: all 1s;
        transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
    }
    .nav-menu.active{
        top: 0;
        left: 0;
        opacity: 1;
        transition: all 1.2s;
        background-color: rgba(73, 83, 88, 0.4);
         backdrop-filter: blur(0.4rem);
        -webkit-backdrop-filter: blur(0.4rem);
    }

    .nav-links{
        display: block;
        width: 98%;
        margin: auto;
        font-size: 1.4rem;
        padding: 1.8rem 0;
    }

    .nav-links:hover{
        background: #67b5d6d2;
        transition: none;
    }
    .signUp-button {
        display: none;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 2rem;
        margin: auto;
        width: 80%;
        text-decoration: none;
        background-color: #800000;
        color: #fff;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 800;
        letter-spacing: 3px;
        
    }
    .nav-links-mobile:hover {
        background-color: #FF0000;
        color: #000000;

    }

    .menu-icons{ 
        display: block;
        cursor: pointer;
    }
    .menu-icons i {
        font-size: 2rem;
        color: #222;
    }
}


.destination {
    margin: 4rem 6rem;
    font-family: 'Comic Neue', cursive;
}

.destination p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
}

.destination h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
}

.first-des {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
    margin-bottom: 9rem;
}
.first-des-reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}


.des-text {
    width: 45%;
    text-align: start;
    font-size: 1.5rem;
}

.des-text h2 {
    padding-bottom: 1.3rem;
}


.images-destination {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-right: .7rem;
    z-index: -8;
}

.images-destination img {
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px;
    /*
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    */
}

.images-destination img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
}

@media screen and (max-width: 850px) {

    .destination {
    margin: 4rem 2rem;
    
    }

    .images-destination {
    padding-right: 1rem;
    }

    .first-des {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4rem;
        margin-bottom: 8rem;
    }
    .first-des-reverse {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 5rem;
    }

    .des-text {
        width: 90%;
        text-align: start;
        font-size: 1.1rem;
    }

    .images-destination {
        width: 90%;
        margin: 1.8rem 0 4rem 0;
    }

    .images-destination img {
        height: 300px;
    }
}
.trip {
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.trip h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
}

.trip p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
}

.trip-card {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.t-card {
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.4);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.t-card p {
    text-align: center;
    padding-bottom: 1rem;
}

.t-card button {
    text-decoration: none;
    background-color: #800000;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
    margin-top: auto; /* Push the item to the bottom */
    margin-bottom: 1.2rem; /* Add a margin from the bottom */
}


.t-card button:hover {
    text-decoration: none;
    background-color: #FF0000;
    color: #000000;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
   
}


.t-image {
    height: 200px;
    width: 300px;
    overflow: hidden;
    border-radius: 7px;
    
}
.t-image img {
    width: 100%;
    height: 100%;
    transition: all 1s ease-in-out;
    border-radius: 7px;
}

.t-card:hover img{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    transition: all 3.5s ease-in-out;
}
.t-card h4 {
    font-size: 1.7rem;
    font-weight: bolder;
    padding: .9rem 0 .2rem 0;
    text-align: center;
    font-family: 'Comic Neue', cursive;
}

@media screen and (max-width: 850px) {
    .trip {
        margin: 4rem 2rem;
    }
    .trip-card {
        flex-direction: column;
    }
    .t-card {
        width: 100%;
        margin-bottom: 1.5rem;
        display:block;
    }

    .t-image {
        height: 200px;
        width: auto;
        overflow: hidden;
        border-radius: 7px;
        
    }
    .t-image img {
        width: 100%;
        height: 100%;
        transition: all 2s ease-in-out;
        border-radius: 7px;
    }
    
    .t-card:hover img{
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
        transition: all 4s ease-in-out;
    }
    .t-card button {
        margin-top: 2rem;
    }
    .t-card p {
        text-align: left;
    }
    .t-card h4 {
        text-align: left;
    }
 
}


.footer {
    padding: 4rem 6rem;
    background-color: #711212;
    color: #fff;

}

.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top div h1, .bottom div h4 {
    font-family: 'Comic Neue', cursive;
    
    font-weight: 900;
    color: #0f0d0d;
}

.top div h1 {
    font-size: 2.2rem;
}


.top a i {
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
    transition: all 300ms;
}
/*
.top i:hover {
    color: #01959a;

}
*/

.top a {
    transition: all 300ms;
}

.top a:hover {
    -webkit-transform: translateY(-5px) scale(1.05);
            transform: translateY(-5px) scale(1.05);
    opacity: 1;
    font-size: 2.1em; 
}

/* Background color for social icons on hover */
.top a:nth-child(1):hover i {
    background-color: #204aa4bb;
    color: #c9ccd1bb;
    padding: 3px;
    border-radius: 5px;
    transition: all 300ms;
}
.top a:nth-child(2):hover i {
    background-color: #b542388d;
    color: #c9ccd1bb;
    padding: 3px;
    border-radius: 5px;
    transition: all 300ms;
}


.top a:nth-child(3):hover i {
    background-color: #188437c0;
    color: #c9ccd1bb;
    padding: 3px;
    border-radius: 5px;
    transition: all 300ms;
}

.bottom {
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.bottom div {
    display: flex;
    flex-direction: column;
    font-family: 'Comic Neue', cursive;
}

.bottom h4{
    font-size: 1.5rem;
    padding: 1rem 0 .8rem 0;
}

.bottom a {
     text-decoration: none;
     color: #fafafa;
     padding-bottom: .4rem;
     font-size: 1.1rem;
}

.bottom a:hover {
    color: #000;
    font-size: 1.2rem;
}
.gallery {
    text-decoration: underline;
    font-size: larger;
}


@media screen and (max-width: 850px) {
    .footer {
        padding: 4rem;

    }

    .footer i{
        margin: 1rem 1rem 0 0;

    }

    .bottom div {
        padding-top: 2rem;
        width: 50%;

    }
}

/* Base Styles */
.about-container {
    max-width: 1400px;
    margin: 60px auto;
    display: grid;
    grid-gap: 60px;
    gap: 60px;
    padding-bottom: 30px; /* Add space at the bottom */
  }
  
  /* Heading for all screen sizes */
  .about-container h1 {
    font-family: 'Exo 2', sans-serif;
    font-size: 3rem; /* Adjusted for smaller screens */
    font-weight: 700;
    color: #310e0e; /* Subtle variation of the brand color */
    text-align: center;
    margin-bottom: 1em; /* Spacing between heading and content */
    z-index: 3;
    position: relative; /* Position set for z-index to take effect */
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
  
  /* Content divs style */
  .about-container div {
    padding: 40px;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
  }
  
  /* Paragraph style */
  .about-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem; /* Adjusted for smaller screens */
    font-weight: 400;
    line-height: 1.8;
    color: #f5f0f0; /* Light color for contrast */
    z-index: 3;
    position: relative; /* Position set for z-index to take effect */
  }
  
  /* Background image with dark overlay for readability */
  .about-container div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: 1; /* Under the text */
    opacity: 0.9; /* Adjust for readability */
  }
  
  /* Specific background images for each section */
  .about-container > div:nth-child(1)::before {
    background-image: url(/static/media/AEDOBoat.7505c6fe.jpg);
  }
  .about-container > div:nth-child(2)::before {
    background-image: url(/static/media/barco-atardecer-pies-del-lago.897b0e0a.jpeg); 
  }
  .about-container > div:nth-child(3)::before {
    background-image: url(/static/media/volcan-villarrica-desdes-isla.23135e32.jpeg); 
  }
  
  /* Larger Screens Layout */
  @media (min-width: 1024px) {
    .about-container {
      grid-template-columns: repeat(3, 1fr); /* 3 columns layout */
      
    }
    
  
    .about-container div {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Align items to the start of the flex-direction */
      justify-content: center;
    }
  
    .about-container div::before {
      background-attachment: fixed; /* Parallax-like effect */
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }
  
  /* Smaller Screens Layout */
  @media (max-width: 1024px) {
    .about-container h1 {
      font-size: 2.7rem; /* Slightly smaller font-size on smaller screens */
      font-family: 'Comic Neue', cursive;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  
    .about-container p {
      font-size: 1.3rem; /* Adjust font-size for smaller screens */
      font-weight: 400;
      font-family: 'Poppins', sans-serif;
      color: rgb(33, 14, 14)
    }
  
    .about-container {
      grid-template-columns: 1fr; /* Stack on smaller screens */
    }
  
    .about-container div {
      text-align: center;
    }
  
    /* Modify the dark overlay for better readability */
    .about-container div::before {
      background: rgba(255, 255, 255, 0.2); /* Darker overlay for contrast */
      opacity: 1; /* Full opacity to ensure text stands out */
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }
  
.book-trip {
    margin: 4rem 6rem;
    color: #2a2a2a;
}


.book-trip h1 {
    font-size: 4rem;
    font-family: 'Comic Neue', cursive;
}

.book-trip-card {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    grid-gap: 2rem;
    gap: 2rem;
}

.book-t-card {
    border: 1px solid gray;
    width: 90vw;
    text-align: start;
    box-shadow:none; 
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
    transition: all 300ms;
}


.book-t-card-reverse {
    border: 1px solid gray;
    width: 90vw;
    text-align: start;
    box-shadow:none; 
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    display: flex;
    flex-direction: row-reverse;
    grid-gap: 2rem;
    gap: 2rem;
}

.book-t-card-reverse:hover {
    border: 1px solid #FFD700;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.4); 
}


.book-t-card:hover {
    border: 1px solid #FFD700;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.4); 
}


.book-t-card p, .book-t-card-reverse p {
    text-align: left;
    width: 90%;
}

.book-t-card .button, .book-t-card-reverse .button {
    max-width: 250px;
    text-decoration: none;
    background-color: #800000;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
    margin-top: auto; /* Push the item to the bottom */
    margin-bottom: 1.2rem; /* Add a margin from the bottom */
}


.book-t-card .button:hover, .book-t-card-reverse .button:hover {
    text-decoration: none;
    background-color: #FF0000;
    color: #000000;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
   
}


.book-t-image {
    height: 400px;
    width: 600px;
    overflow: hidden;
    border-radius: 2px;
    

    
}



.book-t-image img {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    object-fit: cover; /* Add this line */
    transition: all 1s ease-in-out;
    border-radius: 2px;
}
.book-t-body {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.book-list-body {
    padding-left: 60px;
    list-style-type: none;
}

.book-t-card:hover img, .book-t-card-reverse:hover img{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    transition: all 3.5s ease-in-out;
}
.book-t-card h4, .book-t-card-reverse h4 {
    font-size: 2rem;
    padding-bottom: .5rem;
    text-align: center;
}


/* Style for the modal container */
.modal {
  display: none;
  position: fixed;
  z-index: 4; /* Place the modal on top of the card */
  left: 0;
  top: 80px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for the modal content */
.modal-content {
  background-color: white;
  max-width: 600px;
  max-height: 70%;
  min-width: 60vw; /* Set the desired width */
  padding: 0 10px 10px 10px;
  text-align: center;
  border-radius: 10px;
    /* Your existing styles */
  display: flex;
  flex-direction: column;
  grid-gap: 1.3rem;
  gap: 1.3rem;
  justify-content: center;
  align-items: center;
  
}

.modal-content p {
    text-align: center;
}

.modal_number_input {
    display: flex;
    grid-gap: .75rem;
    gap: .75rem;
}

.number_button {
    background-color: #9a9494;
    font-size: 1.6rem;
    font-weight: 900;
    margin: .3rem;
    padding: .375rem;
    width: 3rem;
}

.input_button {
    width: 4rem;
    height: 4rem;
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
}

.modal-content .modal_input {
    height: 1.7rem;
    max-width: 60vw;
    min-width: 240px;

}

.modal-content .modal_input:focus {
    border: 1px solid yellow;
}

/* Style for close button */
.close {
    
    align-self: flex-end;
    cursor: pointer;
    font-size: 48px;
    color: #0a0909;
  }


@media screen and (max-width: 850px) {
    .book-trip {
        margin: 4rem 2rem;
    }
    .book-trip-card {
        flex-direction: column;
    }
    .book-t-card, .book-t-card-reverse {
        width: 100%;
        margin-bottom: 1.5rem;
        display:block;
    }

    .book-t-image {
        height: auto;
        width: 80vw;
        overflow:scroll;
        border-radius: 7px;
        
    }

    .book-t-body {
        width: auto;
        
    }
    .book-t-image img {
        width: 100%;
        height: 100%;
        transition: all 2s ease-in-out;
        border-radius: 7px;
    }
    
    .book-t-card:hover img, .book-t-card-reverse:hover img{
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
        transition: all 4s ease-in-out;
    }
    .book-t-card .button, .book-t-card-reverse .button {
        margin-top: 2rem;
    }
    .book-t-card p {
        text-align: left;
    }
    .book-t-card h4 {
        text-align: left;
    }
 
}

.form-container {
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.form-container form {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}

.form-container input {
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;

}

.form-container textarea { 
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;

}

.form-container button {
    font-size: 1.5rem;
    padding: 1rem;
    border-radius: 7px;
    background-color: #800000;
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .form-container {
        margin: 4rem 2rem;

    }
    .form-container form {
        padding-top: 2rem;
        width: 90%;
    }
}
/* GalleryStyles.css */

.gallery-container {
    background-color: rgba(0, 0, 0, 0.2);
}

.gallery {
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 15px;
    padding: 15px;
}

.gallery-item {
    overflow: hidden;
}

.gallery-item img {
    width: 100%;
    height: auto;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    object-fit: cover;
}

.gallery-item:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 4rem;
    color: white;
    cursor: pointer;
}

.expanded-image {
    width: 80%;
    height: 80%;
    object-fit: contain;
    padding: 20px;
    box-sizing: border-box;
}
