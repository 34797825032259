
.hero {
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;
    
}


img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 1rem 0.8rem 1.5rem 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;

}



.hero-text h1 {
    font-size: 3rem;
    font-family: 'Comic Neue', cursive;
    font-weight: 800;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
}

.hero-text p {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px); /* For compatibility with some browsers */
    max-width: 600px;
    font-family: 'Comic Neue', cursive;
    
    font-size: 1.6rem;
    font-weight: 700;
    font-style: italic;
    color: #000000;
    padding: 0.5rem 0 2rem 0;
}

.hero-text .show {
    text-decoration: none;
    background-color: #800000;
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
}

.hero-text .show:hover {
    text-decoration: none;
    background-color: #FF0000;
    color: #000000;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
   
}
.hero-text .hide {
    display: none;
}



@media screen and (max-width: 555px) {
    .hero-text {
        top: 50%;
    }
    .hero-text h1{
        padding: 5px 10px;
        font-size: 2.5rem;

    }
    .hero-text p{
        font-size: 1.2rem;
        padding: 0 0 2rem 0;
    }

    .hero-text .show {

        padding: .8rem 2rem;
        border-radius: 0.5rem;
        font-size: .8rem;
    }
}
