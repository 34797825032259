.navbarItems {
    background-color: rgba(247, 248, 248, 0.95);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .25);
    width: 95%;
    height: 5rem;
    border-radius: .5rem;
    position: fixed;
    top: 1.25rem;
    left: 50%;
    transform: translate(-50%);
    z-index: 10;
    
}

.navbar-logo {
    padding-top: 1rem;
    color: #222;
    font-size: 2rem;
    cursor: pointer;
    width: 5rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6,auto);
    gap: .7rem;
    list-style-type: none;
    align-items: center;

}

.nav-links {
    text-decoration: none;
    color: #222;
    font-size: 1.4rem;
    font-family: 'Comic Neue', cursive;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;

}
.nav-links:hover {
    background-color: #67b4d6;
    color: #fff;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
     
}

.nav-links i{
    padding-right: .6rem;
}

.nav-links-mobile {
    display: none;
}
.signUp-button {
    padding: 0.8rem 1.6rem;
    white-space: nowrap;
    border-radius:  0.3rem;
    font-size: 1.4rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background-color: #800000;
    color: #fff;
    transition: all .3s;
}

.signUp-button:hover{
    background-color: #FF0000;
    color: #000000;
    transition: all .3s;

}

.menu-icons{
    display: none;
}

@media screen and (max-width: 850px) {

    .navbarItems {
        background-color: rgba(124, 213, 213, 0.01);
        box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .010);
        width: 95%;
        height: 4rem;
        border-radius: .5rem;
        position: fixed;
        top: 1.25rem;
        left: 50%;
        transform: translate(-50%);
        transition: all 1.2s ease-in-out;
        
    }
    .navbarItems:hover{
        height: 5rem;
        background-color: rgba(247, 248, 248, 0.85);
        box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .35); 
        transition: all 0.4s ease-in-out;
    }


    .navbar-logo {
        width: 4rem;
    }


    .navbarItems {
        z-index: 8;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba(61, 62, 80, 0.4);
        width: 100%;
        height: auto;
        position: absolute;
        top: -300%;
        left: 120%;
        opacity: .1;
        align-items: stretch;
        padding: 68px 0 30px 0;
        margin: 0;  
        z-index: -1;
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0.0rem);
        transition: all 1s;
        transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
    }
    .nav-menu.active{
        top: 0;
        left: 0;
        opacity: 1;
        transition: all 1.2s;
        background-color: rgba(73, 83, 88, 0.4);
         backdrop-filter: blur(0.4rem);
        -webkit-backdrop-filter: blur(0.4rem);
    }

    .nav-links{
        display: block;
        width: 98%;
        margin: auto;
        font-size: 1.4rem;
        padding: 1.8rem 0;
    }

    .nav-links:hover{
        background: #67b5d6d2;
        transition: none;
    }
    .signUp-button {
        display: none;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 2rem;
        margin: auto;
        width: 80%;
        text-decoration: none;
        background-color: #800000;
        color: #fff;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 800;
        letter-spacing: 3px;
        
    }
    .nav-links-mobile:hover {
        background-color: #FF0000;
        color: #000000;

    }

    .menu-icons{ 
        display: block;
        cursor: pointer;
    }
    .menu-icons i {
        font-size: 2rem;
        color: #222;
    }
}

@media screen and (max-width: 450px) {

    .navbarItems {
        background-color: rgba(124, 213, 213, 0.01);
        box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .010);
        width: 95%;
        height: 4rem;
        border-radius: .5rem;
        position: fixed;
        top: 1.25rem;
        left: 50%;
        transform: translate(-50%);
        transition: all 1.2s ease-in-out;
        
    }
    .navbarItems:hover{
        height: 5rem;
        background-color: rgba(247, 248, 248, 0.85);
        box-shadow: 0 .3rem .9rem 0 rgba(0, 0, 0, .35); 
        transition: all 0.4s ease-in-out;
    }


    .navbar-logo {
        width: 4rem;
    }


    .navbarItems {
        z-index: 8;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba(61, 62, 80, 0.4);
        width: 100%;
        height: auto;
        position: absolute;
        top: -300%;
        left: 120%;
        opacity: .1;
        align-items: stretch;
        padding: 68px 0 30px 0;
        margin: 0;  
        z-index: -1;
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0.0rem);
        transition: all 1s;
        transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
    }
    .nav-menu.active{
        top: 0;
        left: 0;
        opacity: 1;
        transition: all 1.2s;
        background-color: rgba(73, 83, 88, 0.4);
         backdrop-filter: blur(0.4rem);
        -webkit-backdrop-filter: blur(0.4rem);
    }

    .nav-links{
        display: block;
        width: 98%;
        margin: auto;
        font-size: 1.4rem;
        padding: 1.8rem 0;
    }

    .nav-links:hover{
        background: #67b5d6d2;
        transition: none;
    }
    .signUp-button {
        display: none;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 2rem;
        margin: auto;
        width: 80%;
        text-decoration: none;
        background-color: #800000;
        color: #fff;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 800;
        letter-spacing: 3px;
        
    }
    .nav-links-mobile:hover {
        background-color: #FF0000;
        color: #000000;

    }

    .menu-icons{ 
        display: block;
        cursor: pointer;
    }
    .menu-icons i {
        font-size: 2rem;
        color: #222;
    }
}