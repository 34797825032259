/* GalleryStyles.css */

.gallery-container {
    background-color: rgba(0, 0, 0, 0.2);
}

.gallery {
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 15px;
    padding: 15px;
}

.gallery-item {
    overflow: hidden;
}

.gallery-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    object-fit: cover;
}

.gallery-item:hover img {
    transform: scale(1.1);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 4rem;
    color: white;
    cursor: pointer;
}

.expanded-image {
    width: 80%;
    height: 80%;
    object-fit: contain;
    padding: 20px;
    box-sizing: border-box;
}