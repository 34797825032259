/* Base Styles */
.about-container {
    max-width: 1400px;
    margin: 60px auto;
    display: grid;
    gap: 60px;
    padding-bottom: 30px; /* Add space at the bottom */
  }
  
  /* Heading for all screen sizes */
  .about-container h1 {
    font-family: 'Exo 2', sans-serif;
    font-size: 3rem; /* Adjusted for smaller screens */
    font-weight: 700;
    color: #310e0e; /* Subtle variation of the brand color */
    text-align: center;
    margin-bottom: 1em; /* Spacing between heading and content */
    z-index: 3;
    position: relative; /* Position set for z-index to take effect */
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
  
  /* Content divs style */
  .about-container div {
    padding: 40px;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
  }
  
  /* Paragraph style */
  .about-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem; /* Adjusted for smaller screens */
    font-weight: 400;
    line-height: 1.8;
    color: #f5f0f0; /* Light color for contrast */
    z-index: 3;
    position: relative; /* Position set for z-index to take effect */
  }
  
  /* Background image with dark overlay for readability */
  .about-container div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: 1; /* Under the text */
    opacity: 0.9; /* Adjust for readability */
  }
  
  /* Specific background images for each section */
  .about-container > div:nth-child(1)::before {
    background-image: url('../assets/trip-images/una-hora/AEDOBoat.jpg');
  }
  .about-container > div:nth-child(2)::before {
    background-image: url('../assets/trip-images/cuatro-horas/barco-atardecer-pies-del-lago.jpeg'); 
  }
  .about-container > div:nth-child(3)::before {
    background-image: url('../assets/trip-images/cuatro-horas/volcan-villarrica-desdes-isla.jpeg'); 
  }
  
  /* Larger Screens Layout */
  @media (min-width: 1024px) {
    .about-container {
      grid-template-columns: repeat(3, 1fr); /* 3 columns layout */
      
    }
    
  
    .about-container div {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Align items to the start of the flex-direction */
      justify-content: center;
    }
  
    .about-container div::before {
      background-attachment: fixed; /* Parallax-like effect */
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }
  
  /* Smaller Screens Layout */
  @media (max-width: 1024px) {
    .about-container h1 {
      font-size: 2.7rem; /* Slightly smaller font-size on smaller screens */
      font-family: 'Comic Neue', cursive;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  
    .about-container p {
      font-size: 1.3rem; /* Adjust font-size for smaller screens */
      font-weight: 400;
      font-family: 'Poppins', sans-serif;
      color: rgb(33, 14, 14)
    }
  
    .about-container {
      grid-template-columns: 1fr; /* Stack on smaller screens */
    }
  
    .about-container div {
      text-align: center;
    }
  
    /* Modify the dark overlay for better readability */
    .about-container div::before {
      background: rgba(255, 255, 255, 0.2); /* Darker overlay for contrast */
      opacity: 1; /* Full opacity to ensure text stands out */
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }
  