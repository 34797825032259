.book-trip {
    margin: 4rem 6rem;
    color: #2a2a2a;
}


.book-trip h1 {
    font-size: 4rem;
    font-family: 'Comic Neue', cursive;
}

.book-trip-card {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    gap: 2rem;
}

.book-t-card {
    border: 1px solid gray;
    width: 90vw;
    text-align: start;
    box-shadow:none; 
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    transition: all 300ms;
}


.book-t-card-reverse {
    border: 1px solid gray;
    width: 90vw;
    text-align: start;
    box-shadow:none; 
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    display: flex;
    flex-direction: row-reverse;
    gap: 2rem;
}

.book-t-card-reverse:hover {
    border: 1px solid #FFD700;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.4); 
}


.book-t-card:hover {
    border: 1px solid #FFD700;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.4); 
}


.book-t-card p, .book-t-card-reverse p {
    text-align: left;
    width: 90%;
}

.book-t-card .button, .book-t-card-reverse .button {
    max-width: 250px;
    text-decoration: none;
    background-color: #800000;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
    margin-top: auto; /* Push the item to the bottom */
    margin-bottom: 1.2rem; /* Add a margin from the bottom */
}


.book-t-card .button:hover, .book-t-card-reverse .button:hover {
    text-decoration: none;
    background-color: #FF0000;
    color: #000000;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
   
}


.book-t-image {
    height: 400px;
    width: 600px;
    overflow: hidden;
    border-radius: 2px;
    

    
}



.book-t-image img {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    object-fit: cover; /* Add this line */
    transition: all 1s ease-in-out;
    border-radius: 2px;
}
.book-t-body {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.book-list-body {
    padding-left: 60px;
    list-style-type: none;
}

.book-t-card:hover img, .book-t-card-reverse:hover img{
    transform: scale(1.3);
    transition: all 3.5s ease-in-out;
}
.book-t-card h4, .book-t-card-reverse h4 {
    font-size: 2rem;
    padding-bottom: .5rem;
    text-align: center;
}


/* Style for the modal container */
.modal {
  display: none;
  position: fixed;
  z-index: 4; /* Place the modal on top of the card */
  left: 0;
  top: 80px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for the modal content */
.modal-content {
  background-color: white;
  max-width: 600px;
  max-height: 70%;
  min-width: 60vw; /* Set the desired width */
  padding: 0 10px 10px 10px;
  text-align: center;
  border-radius: 10px;
    /* Your existing styles */
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  justify-content: center;
  align-items: center;
  
}

.modal-content p {
    text-align: center;
}

.modal_number_input {
    display: flex;
    gap: .75rem;
}

.number_button {
    background-color: #9a9494;
    font-size: 1.6rem;
    font-weight: 900;
    margin: .3rem;
    padding: .375rem;
    width: 3rem;
}

.input_button {
    width: 4rem;
    height: 4rem;
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
}

.modal-content .modal_input {
    height: 1.7rem;
    max-width: 60vw;
    min-width: 240px;

}

.modal-content .modal_input:focus {
    border: 1px solid yellow;
}

/* Style for close button */
.close {
    
    align-self: flex-end;
    cursor: pointer;
    font-size: 48px;
    color: #0a0909;
  }


@media screen and (max-width: 850px) {
    .book-trip {
        margin: 4rem 2rem;
    }
    .book-trip-card {
        flex-direction: column;
    }
    .book-t-card, .book-t-card-reverse {
        width: 100%;
        margin-bottom: 1.5rem;
        display:block;
    }

    .book-t-image {
        height: auto;
        width: 80vw;
        overflow:scroll;
        border-radius: 7px;
        
    }

    .book-t-body {
        width: auto;
        
    }
    .book-t-image img {
        width: 100%;
        height: 100%;
        transition: all 2s ease-in-out;
        border-radius: 7px;
    }
    
    .book-t-card:hover img, .book-t-card-reverse:hover img{
        transform: scale(1.3);
        transition: all 4s ease-in-out;
    }
    .book-t-card .button, .book-t-card-reverse .button {
        margin-top: 2rem;
    }
    .book-t-card p {
        text-align: left;
    }
    .book-t-card h4 {
        text-align: left;
    }
 
}