.trip {
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.trip h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
}

.trip p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
}

.trip-card {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.t-card {
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.4);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.t-card p {
    text-align: center;
    padding-bottom: 1rem;
}

.t-card button {
    text-decoration: none;
    background-color: #800000;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
    margin-top: auto; /* Push the item to the bottom */
    margin-bottom: 1.2rem; /* Add a margin from the bottom */
}


.t-card button:hover {
    text-decoration: none;
    background-color: #FF0000;
    color: #000000;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all .3s;
   
}


.t-image {
    height: 200px;
    width: 300px;
    overflow: hidden;
    border-radius: 7px;
    
}
.t-image img {
    width: 100%;
    height: 100%;
    transition: all 1s ease-in-out;
    border-radius: 7px;
}

.t-card:hover img{
    transform: scale(1.3);
    transition: all 3.5s ease-in-out;
}
.t-card h4 {
    font-size: 1.7rem;
    font-weight: bolder;
    padding: .9rem 0 .2rem 0;
    text-align: center;
    font-family: 'Comic Neue', cursive;
}

@media screen and (max-width: 850px) {
    .trip {
        margin: 4rem 2rem;
    }
    .trip-card {
        flex-direction: column;
    }
    .t-card {
        width: 100%;
        margin-bottom: 1.5rem;
        display:block;
    }

    .t-image {
        height: 200px;
        width: auto;
        overflow: hidden;
        border-radius: 7px;
        
    }
    .t-image img {
        width: 100%;
        height: 100%;
        transition: all 2s ease-in-out;
        border-radius: 7px;
    }
    
    .t-card:hover img{
        transform: scale(1.3);
        transition: all 4s ease-in-out;
    }
    .t-card button {
        margin-top: 2rem;
    }
    .t-card p {
        text-align: left;
    }
    .t-card h4 {
        text-align: left;
    }
 
}