

.footer {
    padding: 4rem 6rem;
    background-color: #711212;
    color: #fff;

}

.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top div h1, .bottom div h4 {
    font-family: 'Comic Neue', cursive;
    
    font-weight: 900;
    color: #0f0d0d;
}

.top div h1 {
    font-size: 2.2rem;
}


.top a i {
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
    transition: all 300ms;
}
/*
.top i:hover {
    color: #01959a;

}
*/

.top a {
    transition: all 300ms;
}

.top a:hover {
    transform: translateY(-5px) scale(1.05);
    opacity: 1;
    font-size: 2.1em; 
}

/* Background color for social icons on hover */
.top a:nth-child(1):hover i {
    background-color: #204aa4bb;
    color: #c9ccd1bb;
    padding: 3px;
    border-radius: 5px;
    transition: all 300ms;
}
.top a:nth-child(2):hover i {
    background-color: #b542388d;
    color: #c9ccd1bb;
    padding: 3px;
    border-radius: 5px;
    transition: all 300ms;
}


.top a:nth-child(3):hover i {
    background-color: #188437c0;
    color: #c9ccd1bb;
    padding: 3px;
    border-radius: 5px;
    transition: all 300ms;
}

.bottom {
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.bottom div {
    display: flex;
    flex-direction: column;
    font-family: 'Comic Neue', cursive;
}

.bottom h4{
    font-size: 1.5rem;
    padding: 1rem 0 .8rem 0;
}

.bottom a {
     text-decoration: none;
     color: #fafafa;
     padding-bottom: .4rem;
     font-size: 1.1rem;
}

.bottom a:hover {
    color: #000;
    font-size: 1.2rem;
}
.gallery {
    text-decoration: underline;
    font-size: larger;
}


@media screen and (max-width: 850px) {
    .footer {
        padding: 4rem;

    }

    .footer i{
        margin: 1rem 1rem 0 0;

    }

    .bottom div {
        padding-top: 2rem;
        width: 50%;

    }
}
